.ArtHub-container {
  display: flex;
}

.ArtHub-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2vw; /* Use viewport width for responsive spacing */
  padding: 2vw;
  flex: 3;
}

.art-grid-item {
  text-decoration: none;
  color: inherit;
  border: 1px solid #ccc;
  border-radius: 0.5em; /* Use relative units for border-radius */
  overflow: hidden;
  transition: transform 0.2s;
  background-color: #000;
}

.art-grid-item:hover {
  transform: scale(1.05);
}

.art-grid-thumbnail img {
  width: 100%;
  height: auto;
  display: block;
}

.art-grid-info {
  padding: 1vw; /* Use viewport width for padding */
  text-align: center;
}

.art-grid-info h2 {
  margin: 0.5em 0;
}

.art-grid-date {
  color: gray;
  font-size: 0.5rem; /* Slightly smaller than the base */
}

.art-grid-tags {
  margin-top: 1em;
}

.art-grid-tags .tag {
  background-color: #e0e0e0;
  color: #333;
  padding: 0.5em 1em; /* Relative padding */
  border-radius: 0.4em;
  margin-right: 0.5em;
  display: inline-block;
  font-size: 0.5rem; /* Slightly smaller tag font */
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 2vw 0;
}

.pagination-button {
  margin: 0 0.5vw;
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: solid 0.1vw #fff;
  border-radius: 0.4em;
  cursor: pointer;
}

.pagination-button.active {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  border: 0.2vw solid #fff;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  opacity: 0.8;
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .ArtHub-grid {
    grid-template-columns: 1fr; /* Single column on mobile */
    gap: 1vw; /* Adjust spacing for smaller screens */
    padding: 1vw;
  }

  .art-grid-info h2 {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .art-grid-tags .tag {
    font-size: 0.8rem; /* Slightly larger tags for readability on mobile */
  }

  .pagination {
    flex-wrap: wrap;
  }

  .pagination-button {
    padding: 0.8vw 1vw; /* Adjust button size for smaller screens */
    font-size: 0.9rem;
  }
}
