.StoryHub-container {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.StoryHub-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1200px;
  width: 100%;
}

.Story-grid-item {
  background: #000;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
  text-decoration: none; /* Add this to ensure no underline */
}

.Story-grid-item:hover {
  transform: scale(1.05);
  text-decoration: none; /* Add this for hover state as well */
}


.Story-grid-thumbnail img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.Story-grid-info {
  padding: 1rem;
  text-align: start;
  text-decoration: none;
  color: #fff;
}
.Story-grid-info h2 {
  margin: 0.5em 0;
}

.Story-grid-description {
  color: #aaa;
  font-size: 0.9rem;
}

.Story-grid-tags {
  margin-top: 1rem;
}

.Story-grid-tags .tag {
  background-color: #fff;
  color: #000;
  padding: 0.5rem 1rem;
  border-radius: 0.4em;
  margin-right: 0.5em;
  display: inline-block;
  font-size: 0.8rem;
}

/* Responsive styling */
@media (max-width: 768px) {
  .StoryHub-grid {
    grid-template-columns: 1fr; /* Single column on smaller screens */
    gap: 1rem;
  }
}
.Story-detail{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}