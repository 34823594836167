:root {
  --black: #000;
  --white: #fff;
}
body {
  background-color: var(--black);
  color: var(--white);
  transition: background-color 0.3s, color 0.3s;
}
#type-button{
  padding: 0px;
  background-color: var(--black);
  border: solid var(--white) 1px;
  margin: 10px auto 10px auto;
  width: 400px;
  height: 500px;
  display: flex;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
}
#type-button:hover{
  scale: 110%;
}
#buttonimage{
  margin: 0px;
  height: 400px;
}
#button-image-div{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--white);
}
#button-text-div {
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  color: var(--white);
  width: 100%;
  height: 100%;
}

.content-box-bellow{
  display: flex;
  padding: 40px;
  margin: 10px auto 10px auto;
  border-top: solid var(--white) 1px;
  width: 80%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
  .content-box{
  display: flex;
  padding: 40px;
  margin: 10px auto 10px auto;
  border-top: solid var(--white) 1px;
  width: 80%;
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
#bigimage{
  border: solid var(--white) 1px;
  width: 80%;
  border-radius: 10px;
}
#button-text-div h2 {
  text-decoration: none; /* Removes underline */
}
#type-button {
  text-decoration: none; /* Ensures the entire button link has no underline */
  color: inherit; /* Ensures the text color remains consistent */
}
#type-button:hover {
  text-decoration: none; /* Ensures no underline on hover */
}
/* Header */
header{
  border-bottom: solid var(--white) 1px;
  display: flex;
  color: white;
  height: 150px;
}
.logobox{
  width: 70%;
  margin: auto 10px auto 10px;
}
#Logo{
  height: 100px;
  opacity: 80%;
  transition: 0.3s;
}
#Logo:hover{
  opacity: 100%;
}
/* footer */
footer {
  border-top: solid var(--white) 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 10px 20px; /* Add padding for better spacing */
}

.logobox {
  flex: 1; /* Takes up space on the left */
  display: flex;
  justify-content: flex-start;
}

.footbox_sosals {
  display: flex;
  gap: 10px; /* Adds space between icons */
  align-items: center;
  justify-content: flex-end;
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin-left: 20px; 
}

#FootDownBad {
  height: 50px;
  opacity: 80%;
  transition: 0.3s;
}

#FootDownBad:hover {
  opacity: 100%;
}

.sosals_image {
  width: 50px;
  height: 50px;
  border: solid white 1px;
  border-radius: 50%;
  background-color: transparent;
  transition: 0.3s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 80%;
}

.sosals_image:hover {
  opacity: 100%;
  scale: 110%;
}

#sosalmedia {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .logobox, .footbox_sosals, .contacts {
    flex: none;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contacts {
      align-items: center;
      margin-left: 0;
  }

  .sosals_image {
      width: 30px;
      height: 30px;
  }

  #FootDownBad {
      height: 70px;
  }
}

@media (max-width: 768px) {
  #type-button {
    /* width: 90%; Make the button take most of the screen width */
    height: auto; /* Adjust height to fit content */
  }

  .content-box {
    flex-direction: column; /* Stack links vertically */
    gap: 15px; /* Adjust gap between stacked items */
  }
}
.tag-box {
  padding: 20px;
  background-color: var(--black);
  border-left: 1px solid var(--white);
  border-bottom: 1px solid var(--white);
  border-radius: 0px 0px 0px 4px;
  display: flex;
  flex-direction: column;
}
.tag-box h3 {
  margin-bottom: 10px;
}
.tag-button {
  display: block;
  margin: 5px 0;
  padding: 10px;
  background-color: var(--black);
  color: var(--white);
  border: solid 1px var(--white);
  border-radius: 4px;
  cursor: pointer;
}
.tag-button.active {
  background-color: var(--white);
  color: var(--black);
}
.tag-button.clear {
  background-color: #ff4d4d;
  color: #fff;
}
.tag-button:hover {
  opacity: 0.8;
}
.art-detail{
  padding: 40px;
  margin: 10px auto 10px auto;
  border-top: solid var(--white) 1px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}